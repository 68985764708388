<template>
  <div>
    <div v-if="!permissions">
      <div class="input-box">
        <el-input
          v-show="key"
          v-model="row.remark"
          size="small"
          class="id"
          :autofocus="key"
          @blur="handleInputBlur(row)"
        />
      </div>
      <span
        v-show="!key"
        @click="$store.state.btn_if.indexOf(val.pw) != -1 && showinput(row)"
      > {{ row.remark }}</span
      >
      <i
        v-show="!key"
        :class="$store.state.btn_if.indexOf(val.pw) != -1 && 'el-icon-edit'"
        @click="$store.state.btn_if.indexOf(val.pw) != -1 && showinput(row)"
      />
    </div>
    <div v-if="permissions">
      <div class="input-box">
         <!-- 12321 -->
        <el-input
          v-show="key"
          v-model="row.remark"
          size="small"
          class="id"
          :autofocus="key"
          @blur="handleInputBlur(row)"
        />
      </div>
      <span
        v-show="!key"
        @click="showinput(row)"
      >
    {{ row.remark }}</span
      >
      <i
        v-show="!key"
        :class="'el-icon-edit'"
        @click="showinput(row)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ['row', 'val', 'permissions',],
  data() {
    return {
      key: false
    }
  },
  methods: {
    handleInputBlur(row) {
      console.log(1234)
      const post =async (type)=> {
        var {data: res} = await this.$http.post(this.val.url, {...row, sync: type})
        if (res.errorCode == 200) {
          this.$emit('getlist')
          this.key = false
        }
      }
      const post1=async (type)=>{
        var { data: res } = await this.$http.post(this.val.url, {
          ...row,
          id: row.userId,
          sync: type
        })
        if (res.errorCode == 200) {
          this.$emit('getlist')
          this.key = false
        }

      }
      this.$confirm(this.val.user, '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        if (this.val.userId) {
           post1(1)
          this.$message.success('已同步')
        } else {
          post(1)
          this.$message.success('已同步')
        }

      }).catch(() => {
        if (this.val.userId) {
          post1(0)
          this.$message.success('未同步')
        } else {
          post(0)
          this.$message.success('未同步')
        }

      });



    },
    showinput() {
      this.key = true
      setTimeout(() => {
        $('.id input').focus()
      }, 10)
    }
  }
}
</script>

<style lang="less" scoped></style>
